import { h, Component } from 'preact';
import { Icon } from './Icon';

export interface IDropdownProps {
    label: object;
    children: object;
    fullWidth?: boolean;
    isOpen: (dropdownId: string) => boolean;
    toggle: (dropdownId: string) => void;
    dropdownId: string;
}

export class Dropdown extends Component<IDropdownProps> {
    render({ fullWidth = false, label, children, isOpen, toggle, dropdownId }) {
        let className = `__son-dropdown`;
        if (isOpen(dropdownId)) { className += ' __son-dropdown--open'; }
        if (fullWidth) { className += ' __son-dropdown--full-width'; }

        const handleDropdownClick = (e: any) => {
            // the anchor tag has an href, and we don't want the url to actually change
            e.preventDefault();

            toggle(dropdownId);
        };

        return (
            <div className={className} data-dropdown-id={dropdownId}>
                <a href="#" class="__son-dropdown-toggle" onClick={handleDropdownClick}>
                    {label}
                    <Icon hint="chevron-down" size="mini" className="__son-dropdown-icon" />
                </a>
                <div class="__son-dropdown-content">
                    {children}
                </div>
            </div>
        );
    }
}
