import urlJoin from 'url-join';
import { AsureUIExceptionFromApiResponse } from '../helpers/AsureUIExceptionFromApiResponse';

interface IRequestConfig {
  url: string;
  accept?: string;
  method?: RequestMethod;
  body?: object;
}

type RequestMethod = 'GET' | 'POST';

interface INavApiClientConfig {
  apiBaseUrl: string;
  accessToken: string;
}

export class NavApiClient {
  constructor(private config: INavApiClientConfig) {}

  private request = async ({ url, accept, method = 'GET', body }: IRequestConfig): Promise<any> => {
    const absoluteUrl = urlJoin(this.config.apiBaseUrl, 'smart-office-nav', url);

    const headers = {
      authorization: `Bearer ${this.config.accessToken}`,
      accept: accept || 'application/json',
    };
    if (body) {
      headers['content-type'] = 'application/json';
    }

    const res = await fetch(absoluteUrl, {
      method,
      headers,
      body: Boolean(body) ? JSON.stringify(body) : undefined
    });

    const responseBody = await res.json();

    if (!res.ok) {
      throw new AsureUIExceptionFromApiResponse({ code: 'nav-api.failed', developerMessage: 'Error fetching navigation', response: {
         status: res.status,
         body: responseBody,
         request: {
           method,
           url: res.url,
           body
         }
        }
      });
    }

    return responseBody;
  }

  getCompanies = async ({ tenantId,  accountId }) => {
    return await this.request({ url: `/tenants/${tenantId}/accounts/${accountId}/companies`, method: 'GET' });
  }

  getNavigation = async ({ tenantId, accountId, companyId, navLocalOverridePayload }: any) => {
    const url = `/tenants/${tenantId}/accounts/${accountId}/companies/${companyId}`;
    const method = navLocalOverridePayload ? 'POST' : 'GET';
    const body = navLocalOverridePayload;

    return await this.request({ url, method, body });
  }
}
