import { NavApiClient } from './NavApiClient';

interface CacheParams {
    // cacheKey is the key we use for localStorage
    cacheKey: string;

    // cacheBustKey allows us to not use a cache (eg because it is for a different user / tenant)
    // this value is NOT part of the localStorage key so that we do NOT end up with a different localStorage entry
    // for different users, which we would potentially need to clean up
    cacheBustKey: string;
}
interface GetThroughCacheParams<T> extends CacheParams {
    originDelegate: () => Promise<T>;
}

/**
 * This class will return from local storage cache, and update local storage in the background.
 * It will not cause the application to update if what it gets from origin differs from what it already returned from localStoage.
 */
export class EventuallyConsistentCacheNavApiClient {
  constructor(private apiClient: NavApiClient) {}

  private getFromCache = async <T>({ cacheKey, cacheBustKey }: CacheParams): Promise<T> => {
    const cacheString = window.localStorage.getItem(cacheKey);
    if (cacheString) {
      const cache = JSON.parse(cacheString);
      if (cache.cacheBustKey === cacheBustKey) {
        return cache.value;
      }
    }
    throw new Error('cache-miss');
  }

  private getThroughCache = async <T>({ cacheKey, originDelegate, cacheBustKey }: GetThroughCacheParams<T>): Promise<T> => {
    const originPromise = originDelegate().then(value => {
      localStorage.setItem(cacheKey, JSON.stringify({ value, cacheBustKey }));
      return value;
    });
    return this.getFromCache<T>({ cacheKey, cacheBustKey }).catch(() => originPromise);
  }

  getCompanies = async ({ tenantId,  accountId }) => {
    return this.getThroughCache({
      cacheKey: 'EventuallyConsistentCacheNavApiClient.getCompanies',
      originDelegate: () => this.apiClient.getCompanies({ tenantId, accountId }),
      cacheBustKey: `${tenantId}-${accountId}`
    });
  }

  getNavigation = async ({ tenantId, accountId, companyId, navLocalOverridePayload }: any) => {
    // navLocalOverridePayload is only present for local development, but if it is, and it changed, we don't want to use the cache
    // or the developer will be frustrated wondering why their local nav change isn't taking effect until they refresh a couple of times
    const cacheBustKey = `${tenantId}-${accountId}` + navLocalOverridePayload ? JSON.stringify(navLocalOverridePayload) : '';

    return this.getThroughCache({
        cacheKey: 'EventuallyConsistentCacheNavApiClient.getNavigation',
        originDelegate: () => this.apiClient.getNavigation({ tenantId, accountId, companyId, navLocalOverridePayload }),
        cacheBustKey
      });
  }
}
