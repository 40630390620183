import { h, Component } from 'preact';
import { INavLink } from '../models';
import { SubNavItemList } from './SubNavItemList';
import { Dropdown, IDropdownProps } from './Dropdown';
import { SearchInput } from './SearchInput';

interface ITopLevelNavItemProps {
  link: INavLink;
  dropdownProps: Partial<IDropdownProps>;
}

interface ITopLevelNavItemState {
  children: INavLink[];
}

export class TopLevelNavItem extends Component<ITopLevelNavItemProps, ITopLevelNavItemState> {
  constructor(props) {
    super();
    this.state = { children: props.link.children || [] };
  }

  private onSearchChange = (search: string) => {
    let children: INavLink[] = this.props.link.children || [];
    if (search) {
      children = this.decorateRecursively(children, search.toLowerCase());
    }
    this.setState({ children });
  }

  private decorateRecursively(links: INavLink[], lowerCaseSearch: string): INavLink[] {
    const decorated: INavLink[] = [];
    for (const link of links) {
      decorated.push({
        ...link,
        doesNotMatchSearch: link.label.toLowerCase().indexOf(lowerCaseSearch) === -1,
        children: link.children ? this.decorateRecursively(link.children, lowerCaseSearch) : undefined
      });
    }
    return decorated;
  }

  render({ dropdownProps, link }) {
    const { label } = link;

    return (
      <li data-section-type="top-level-nav-item">
          <Dropdown
            label={label}
            fullWidth
            {...dropdownProps}
            dropdownId={`nav-${label}`}
          >
            <div className="__son-nav-search">
              <SearchInput onChange={(s) => this.onSearchChange(s)} />
            </div>

            <div className="__son-nav-dropdown-content">
              <SubNavItemList level={0} links={this.state.children} />
            </div>
          </Dropdown>
      </li>
    );
  }
}
