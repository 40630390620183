import { h, Component } from 'preact';
import { TopLevelNavItemList } from './TopLevelNavItemList';
import { ICompany, INavLink, IUser } from '../models';
import { AccountNav } from './AccountNav';
import '../style.scss';

interface INavProps {
  // we usually get homeUrl from the API, but we may get it passed (when running locally and needing homeurl to be localhost)
  homeUrl?: string;

  logoUrl: string;
  user: IUser;
  company: ICompany;
  logoutUrl: string;
  getNavigation: () => Promise<{ homeUrl: string, navigation: INavLink[]}>;
}

interface INavState {
  isOpen: boolean;
  openDropdownId?: null | string;
  homeUrl?: string;
  navigation?: INavLink[];
}

export class Nav extends Component<INavProps, INavState> {
  state = {
    isOpen: false,
    openDropdownId: null,
    homeUrl: this.props.homeUrl,
    navigation : []
  };

  initialBodyOverflowY?: string;

  componentDidMount = async () => {
    this.setupCloseDropdownOnClickEventListener();
    const { homeUrl, navigation } = await this.props.getNavigation();
    // TODO: consider toast on error so that the user knows that their nav failed to load, and refreshing might fix it...
    this.setState({
      homeUrl: this.props.homeUrl || homeUrl,
      navigation
    });
  }

  /**
   * If the user clicked on an element that isn't in the open dropdown, close it
   * OR If the user clicked on link inside the open dropdown, close it
   */
  setupCloseDropdownOnClickEventListener = () => {
    window.addEventListener('click', (e) => {
      const dropdownIdToClose = this.state.openDropdownId;
      if (!dropdownIdToClose) {
        return;
      }

      const openDropdownElement = document.querySelector(`[data-dropdown-id="${dropdownIdToClose}"]`);
      if (!openDropdownElement) {
        console.error(`Expected to find element for open dropdown ${dropdownIdToClose} but didn't.`);
        return;
      }

      const target = e.target as HTMLElement;
      if (!target) {
        return;
      }

      const isClickedElementSubNavLink = isSubNavLink(target) || isSubNavLink(target.parentElement);

      if (!openDropdownElement.contains(target) || isClickedElementSubNavLink) {
        // Bug fix for clickng to open a dropdown when another one is open only closing the other dropdown and not opening the new one
        // Specifically this bug was reproducible in IE11 and in Cypress tests as its a race condition around setState's batching
        // Close the dropdown if the dropdown that is open is the one we were trying to close
        // Because setState is async, there might be a pending update that we'd blow away if we just set openDropdownId: null
        // We use setState(callback) to get the current value and only set it to null if the open dropdown is the one we were trying to close
        this.setState(({ openDropdownId }) => ({
          openDropdownId: (dropdownIdToClose === openDropdownId) ? null : openDropdownId
        }));
      }
    });
  }

  toggleDropdown = dropdownId => {
    this.setState({ openDropdownId: this.state.openDropdownId === dropdownId ? null : dropdownId });
  }

  isDropdownOpen = dropdownId => this.state.openDropdownId === dropdownId;

  /**
   * Mobile adds a toggle button at the top since you don't have room to see the top level nav items and user menu
   */
  toggleMobileMenu = () => {
    if (!this.state.isOpen) {
      // if we are opening the mobile nav, then cache body style for overflow
      this.initialBodyOverflowY = document.body.style.overflow || '';
      document.body.style.overflowY = 'hidden';
    } else {
      // if we are closing the mobile nav, then put the overflow back
      document.body.style.overflow = this.initialBodyOverflowY as string;
    }

    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { logoUrl, user, company, logoutUrl } = this.props;
    const { isOpen, homeUrl, navigation } = this.state;
    const dropdownProps = { isOpen: this.isDropdownOpen, toggle: this.toggleDropdown };

    return (
      <div id="smart-office-navigation-container">
        <div id="smart-office-navigation">
          <div className={`__son-header ${isOpen ? '__son-header--open' : ''}`}>

            <div className="__son-logo" data-section-id="company-logo" style={{ backgroundImage: `url(${logoUrl})` }}>
              <a href={homeUrl} />
            </div>

            <div className="__son-navbar">
              <div className="__son-navbar-main">
                <TopLevelNavItemList
                  links={navigation}
                  dropdownProps={dropdownProps}
                />
              </div>

              <AccountNav
                user={user}
                company={company}
                dropdownProps={dropdownProps}
                logoutUrl={logoutUrl}
              />
            </div>

            <button className="__son-navbar-toggle" data-id="navbar-toggle" onClick={this.toggleMobileMenu}>
              <i className="__son-navbar-icon-bar" />
              <i className="__son-navbar-icon-bar" />
              <i className="__son-navbar-icon-bar" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function isSubNavLink(element) {
  return element && element.tagName.toUpperCase() === 'A' && element.getAttribute('data-sub-nav-link');
}
