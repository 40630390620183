import { IUser } from '../models';

const decode = (jwt: string): string => {
  return JSON.parse(atob(jwt.split('.')[1]));
};

export const getUserFromIdentityToken = (jwt: string): IUser => {
  const { account } = decode(jwt) as any;

  return {
    accountId: account.id,
    name: `${account.givenName} ${account.surname}`,
    email: account.email,
    tenantId: account.tenantId
  };
};
