import { h, render } from 'preact';
import { Nav } from './components/Nav';
import { getUserFromIdentityToken } from './helpers';
import { NavApiClient } from './clients/NavApiClient';
import { EventuallyConsistentCacheNavApiClient } from './clients/EventuallyConsistentCacheNavApiClient';
import { INavLink } from './models';
import { AsureUIException } from './helpers/AsureUIException';

export interface ISmartOfficeNavigationParams {
  apiBaseUrl: string;
  logoutUrl: string;
  accessToken: string;
  identityToken: string;

  navLocalOverride?: {
    navigationId: string,
    url: string,
    mediaType: string
  };

  // optional overrides likely only used by smart office nav developers to mix and match what they are using locally and remotely
  // usually these are all inferred to keep the surface area to the consumer small
  overrides: {
    navApiBaseUrl?: string; // otherwise inferred from apiBaseUrl
    homeUrl?: string; // otherwise use the one from the nav api
  };

  // backwards compatibility, this can be removed once Pigeons are Birds are on the latest Smart Office
  // otherwise a release of SmartOfficeNav will break their local DX
  homeLocalOverrideUrl?: string;
}

export class SmartOfficeNavigation {

  // static helper to construct an instance and invoke mount() on it
  static mount = ({ mountElement, ...params }: ISmartOfficeNavigationParams & { mountElement: Element }) => {
    return new SmartOfficeNavigation(params).mount(mountElement);
  }

  constructor(private params: ISmartOfficeNavigationParams) {}

  private buildApiClient = () => {
    const { apiBaseUrl, accessToken } = this.params;
    const overrides = this.params.overrides || {};
    const apiClient = new NavApiClient({ apiBaseUrl: overrides.navApiBaseUrl || apiBaseUrl, accessToken });
    return new EventuallyConsistentCacheNavApiClient(apiClient);
  }

  mount = async (mountElement: Element) => {
    const { logoutUrl, accessToken, identityToken, homeLocalOverrideUrl } = this.params;
    const overrides = this.params.overrides || {};

    // backwards compatibility
    overrides.homeUrl = overrides.homeUrl || homeLocalOverrideUrl;

    // If the user is not logged in, don't render the nav
    if (!accessToken || !identityToken) {
      throw new Error('Both accessToken and identityToken are required.');
    }

    const user = getUserFromIdentityToken(identityToken);

    const apiClient = this.buildApiClient();

    const navLocalOverridePayload = await this.loadLocalOverride();

    const companies = await apiClient.getCompanies({ accountId: user.accountId, tenantId: user.tenantId });
    if (companies.length === 0) {
      // we will eventually want to support no company context, eg SB admins who are not mapped to a specific company
      throw new AsureUIException({
        code: 'son-ui:no-company-context',
        displayMessage: 'Sorry, it appears that your user is not mapped to any companies. If you think you should have access to a company, please contact your administrator for assistance.',
        developerMessage: 'Your user is not mapped to any companies. See Smart Office Navigation README for code: no-company-context.'
      });
    } else if (companies.length > 1) {
      // we will need to support company switching, before we can support users that are mapped to more than one company
      throw new AsureUIException({ code: 'son-ui:multi-company-context', displayMessage: 'Sorry, we’re working on adding support for users with access to more than one company. If you don’t think you should have access to more than one company, please contact your administrator for assistance.' });
    }

    const company = companies[0];
    const companyId = company.ids.smartOffice;

    const getNavigation = () => apiClient.getNavigation({
      accountId: user.accountId,
      tenantId: user.tenantId,
      companyId,
      navLocalOverridePayload
    });

    render(
      <Nav
        homeUrl={overrides.homeUrl}
        logoUrl={company.logoUrl}
        logoutUrl={logoutUrl}
        user={user}
        company={company}
        getNavigation={getNavigation}
      />,
      mountElement
    );

    return { company };
  }

  async loadLocalOverride() {
    if (!this.params.navLocalOverride) {
      return;
    }

    const { navigationId, mediaType, url } = this.params.navLocalOverride;

    try {
      const navLocalOverrideResponse = await fetch(url);

      if (!navLocalOverrideResponse.ok) {
        throw navLocalOverrideResponse;
      }

      const payload = await navLocalOverrideResponse.json();

      // This mutates payload
      this.replacePlaceholderBaseUrlsForLocalDevelopment(payload.navigation || []);

      return {
        navigationId,
        mediaType,
        payload
      };
    } catch (e) {
      let message = `Failed to load nav from ${this.params.navLocalOverride.url}.`;

      // If it's a failed request
      if (e.status) {
        message += ` Status: ${e.status}.`;
        const body = await tryGetBody(e) || 'NONE';
        message += ` Body: ${body}.`;
      }

      if (e.message) {
        message += ` Error: ${e.message}.`;
      }

      console.error(message);
      throw e;
    }
  }

  /**
   * This code is only relevant when running locally and overriding the nav
   * In this case, we want to replace the domain with the local domain so links still work for local development.
   * If we don't do this replacement locally, the API will replace smartOfficeHost with a value from the environment config and links will go the wrong env (not local)
   */
  private replacePlaceholderBaseUrlsForLocalDevelopment = (navLinks: INavLink[]) => {
    const baseUrlToReplace = 'https://{tenantSubdomain}.{smartOfficeHost}';
    const localDevelopmentBaseUrl = `http://${window.location.host}`;

    for (const navLink of navLinks) {
      if (navLink.href) {
        navLink.href = navLink.href.replace(baseUrlToReplace, localDevelopmentBaseUrl);
      }
      if (navLink.children) {
        this.replacePlaceholderBaseUrlsForLocalDevelopment(navLink.children);
      }
    }
  }
}

async function tryGetBody(response) {
  try {
    return await response.text();
  } catch (e) {
    // do nothing
  }
}
