import { h, Component } from 'preact';
import { INavLink } from '../models';
import { SubNavItem } from './SubNavItem';

interface INavItemListProps {
  links: INavLink[];
  level: number;
}

export class SubNavItemList extends Component<INavItemListProps> {
  render({ level, links }: INavItemListProps) {
    return (
      <ul className={`__son-subnav-item-list __son-subnav-item-list--level-${level}`} data-section-type="nav-flyout">
          {links.map(subLink => (
            <SubNavItem key={subLink.href} link={subLink} level={level} />
          ))}
      </ul>
    );
  }
}
