import { h, Component } from 'preact';
import { ICompany, IUser } from '../models';
import { Icon } from './Icon';
import { Dropdown, IDropdownProps } from './Dropdown';

interface IAccountNavProps {
    user: IUser;
    company: ICompany;
    logoutUrl: string;
    dropdownProps: Partial<IDropdownProps>;
}

export class AccountNav extends Component<IAccountNavProps> {
    render({ user, company, logoutUrl, dropdownProps }) {
        return (
            <div class="__son-account-nav">
                <Dropdown
                    label={
                        <span>
                            <Icon hint="user" />
                            <span class="__son-account-nav-username">{user.name}</span>
                        </span>
                    }
                    {...dropdownProps}
                    dropdownId="account-nav"
                >
                    <div class="__son-dropdown-content-item" data-section-id="company-info">
                        <div class="__son-dropdown-content-heading">Company Info</div>
                        <div>{company.name}</div>
                        <div class="__son-dropdown-content-subtext">{company.code}</div>
                    </div>
                    <div class="__son-dropdown-content-divider" />
                    <div class="__son-dropdown-content-item" data-section-id="user-info">
                        <div class="__son-dropdown-content-heading">User Info</div>
                        <div>{user.email}</div>
                    </div>
                    <div class="__son-dropdown-content-divider" />
                    {Boolean(logoutUrl) &&
                        <div class="__son-dropdown-content-item">
                            <a href={logoutUrl} class="__son-account-nav-logout" data-id="sign-out">
                                <Icon hint="sign-out" />
                                Sign Out
                            </a>
                        </div>
                    }
                </Dropdown>
            </div>
        );
    }

}
