import { h, Component } from 'preact';

interface INavItemProps {
  label: string;
}

export class SubNavLabel extends Component<INavItemProps> {
  render({ label }) {
    return (
      <span className="__son-subnav-label">
          {label}
      </span>
    );
  }
}
