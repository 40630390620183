import { h, Component } from 'preact';
import { INavLink } from '../models';
import { SubNavItemList } from './SubNavItemList';
import { SubNavLabel } from './SubNavLabel';
import { Icon } from './Icon';

interface INavItemProps {
  link: INavLink;
  level: number;
}

export class SubNavItem extends Component<INavItemProps> {
  render({ level, link }) {
    const { href, label, iconHint, target, doesNotMatchSearch, children = [] } = link;

    const hasSubNav = children.length > 0;
    const classes = '__son-subnav-item' + (!hasSubNav && doesNotMatchSearch ? ' __son-subnav-item--dimmed' : '');

    return (
      <li className={classes} data-section-type="sub-nav-item">
          {Boolean(href) ?
            <a href={href} target={target} data-sub-nav-link>
              {Boolean(iconHint) && <Icon hint={iconHint} />}
              <span>{label}</span>
            </a> :
            <SubNavLabel label={label} />
          }
          {hasSubNav &&
            <SubNavItemList links={children} level={level + 1} />
          }
      </li>
    );
  }
}
