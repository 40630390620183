import { h } from 'preact';

interface IIconProps {
    hint: string;
    size?: 'default' | 'mini';
    className?: string;
}

export const Icon = ({ hint, className, size = 'default' }: IIconProps) => {
    return (
        <i class={`fa fa-${hint} __son-icon-${size} ${className || ''}`} />
    );
};
