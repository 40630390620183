
export interface IAsureUIExceptionParams {
  code: string;
  displayMessage?: string;
  developerMessage?: string;
  innerException?: Error;
}

export class AsureUIException extends Error {
  code: string;
  displayMessage: string;

  constructor({ code, displayMessage, developerMessage, innerException }: IAsureUIExceptionParams) {
    displayMessage = displayMessage || 'Something went wrong. Please try again.';

    let errorMessage = developerMessage || displayMessage;

    errorMessage += ` (code: ${code})`;

    if (innerException) {
      errorMessage += `\n\n\t------------------------------------ InnerException ------------------------------------\n\t${innerException.toString().split('\n').join('\n\t')}\n\n\t-----------------------------------------------------------------------------------\n\n`;
    }

    super(errorMessage);

    this.displayMessage = displayMessage;
    this.code = code;
  }
}
