import { h, Component } from 'preact';

interface ISearchInputProps {
  onChange: (search: string) => void;
}

export class SearchInput extends Component<ISearchInputProps> {
  render() {
    return (
      <div className="__son-search-container">
        <span className="__son-search-icon">
          <i className="fa fa-search"></i>
        </span>
        <input
          className="__son-search-input"
          placeholder="find menu item"
          data-section-type="nav-search"
          type="search"
          onInput={(e: any) => this.props.onChange(e.target.value)}
        />
      </div>
    );
  }
}
