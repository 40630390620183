import { IAsureUIExceptionParams, AsureUIException } from './AsureUIException';

interface IApiResponse {
  request: {
    url: string;
    body?: any;
    method: string
  };
  status: number;
  body?: any;
}

export class AsureUIExceptionFromApiResponse extends AsureUIException {
  constructor({ code, displayMessage, developerMessage, response }: IAsureUIExceptionParams & { response: IApiResponse }) {
    const { request: { url, body: requestBody, method } , status, body: responseBody } = response;

    // TODO: we should support responseBody already being a string but we don't have a use case yet
    // TODO: when responseBody is not an object no apiErrorMessage (so we don't duplicate the responseText)
    const apiErrorMessage = responseBody.message || '';
    let errorMessage = `HTTP Error: ${apiErrorMessage}\n\nResponse: ${status}\n${JSON.stringify(responseBody, null, 2)}`;

    errorMessage += `\n\nRequest: ${method} ${url}`;

    if (requestBody) {
      // TODO: we should support requestBody already being a string but we don't have a use case yet
      errorMessage += `\n${JSON.stringify(requestBody, null, 2)}`;
    }
    const innerException = new Error(errorMessage);

    super({ code, developerMessage, displayMessage, innerException });
  }
}
