import { h, Component } from 'preact';
import { INavLink } from '../models';
import { TopLevelNavItem } from './TopLevelNavItem';
import { IDropdownProps } from './Dropdown';

interface ITopLevelNavItemListProps {
    links: INavLink[];
    dropdownProps: Partial<IDropdownProps>;
}

export class TopLevelNavItemList extends Component<ITopLevelNavItemListProps> {
  render({ links, dropdownProps }) {
    return (
      <ul className="__son-nav-item-list--level-0">
          {links.map(subLink => (
            <TopLevelNavItem key={subLink.href} link={subLink} dropdownProps={dropdownProps} />
          ))}
      </ul>
    );
  }
}
